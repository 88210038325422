<template>
  <div class="page-content">
    <div class="container-fluid">
      <div class="row">
        <div class="col-12">
          <div class="
              page-title-box
              d-sm-flex
              align-items-center
              justify-content-between
            ">
            <h4 class="mb-sm-0">Laporan Laba Rugi</h4>

            <div class="page-title-right">
              <ol class="breadcrumb m-0">
                <li class="breadcrumb-item active">Form Laporan Laba Rugi</li>
              </ol>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-xl-12">
          <div class="card">
            <div class="card-header align-items-center d-flex">
              <h4 class="card-title mb-0 flex-grow-1">
                Form Laporan Laba Rugi
              </h4>
              <div class="flex-shrink-0">
                <router-link :to="{ name: 'income.statement' }" type="button"
                  class="btn btn-warning waves-effect waves-light"><i
                    class="bx bx-chevrons-left font-size-16 align-middle me-2"></i>
                  Kembali
                </router-link>
              </div>
            </div>
            <div class="card-body">
              <div class="alert alert-success" role="alert" v-if="share_period_entitled">
                <p>periode saham yang berhak menerima hasil pada bulan <strong>{{ income_statement.month }}</strong> tahun <strong>{{ income_statement.year }}</strong> adalah :  <strong> {{ sharePeriodEntitledToString() }}
                  </strong> dengan total saham sebanyak <strong>{{ share_total }}</strong> slot</p>
              </div>
              <form class="" @submit.prevent="submit">
                <div class="row mb-3">
                  <div class="col-md-2">
                    <label class="form-label mt-2">Tahun</label>
                  </div>
                  <div class="col-md-4">
                    <input type="text" class="form-control" placeholder="Massukkan tahun"
                      v-model="income_statement.year" @change="getShareEntitled()" required />
                  </div>
                </div>

                <div class="row mb-3">
                  <div class="col-md-2">
                    <label class="form-label mt-2">Bulan</label>
                  </div>
                  <div class="col-md-4">
                    <select v-model="income_statement.month" class="form-select" @change="getShareEntitled()" required>
                      <option value="">Pilih Bulan</option>
                      <option :value="month" v-for="month in list.month" :key="month">
                        {{ month }}
                      </option>
                    </select>
                  </div>
                </div>

                <div class="row mb-3">
                  <div class="col-md-2">
                    <label class="form-label mt-2">Total Pendapatan</label>
                  </div>
                  <div class="col-md-5">
                    <CurrencyInput v-model="income_statement.total_revenue" :placeholder="'Masukkan Total Pendapatan'"
                      :options="currency_config" />
                  </div>
                </div>

                <div class="row mb-3">
                  <div class="col-md-2">
                    <label class="form-label mt-2">Total Beban</label>
                  </div>
                  <div class="col-md-5">
                    <CurrencyInput v-model="income_statement.total_expanses" :placeholder="'Masukkan Total Beban'"
                      :options="currency_config" />
                  </div>
                </div>

                <div class="float-end">
                  <router-link :to="{ name: 'income.statement' }" type="button"
                    class="btn btn-warning waves-effect waves-light me-2"><i
                      class="bx bx-chevrons-left font-size-16 align-middle me-2"></i>
                    Kembali
                  </router-link>
                  <button :disabled="load" type="submit" class="btn btn-primary waves-effect waves-light">
                    <i class="bx font-size-16 align-middle me-2"
                      :class="{ 'bx-loader bx-spin': load, 'bx-save': !load }"></i>
                    Simpan
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import httpService from "../../services/http-service";
import { endpoint } from "../../host";
import Swal from "sweetalert2";
import errorService from "../../services/error-service";
import CurrencyInput from "../../components/CurrencyInput.vue";
import moment from "moment";
export default {
  components: {
    CurrencyInput,
  },
  created() {
    let params = this.$route.params;
    if (params.id !== undefined) {
      this.assignParams(params);
    }
    this.getShareEntitled();
  },
  data() {
    return {
      title: null,
      load: false,
      share_period_entitled: null,
      share_total: 0,
      currency_config: {
        currency: "IDR",
      },
      income_statement: {
        id: null,
        year: moment().year(),
        month: moment().format('MM'),
        total_revenue: null,
        total_expanses: null,
        _method: "POST",
      },
      list: {
        month: [
          "01",
          "02",
          "03",
          "04",
          "05",
          "06",
          "07",
          "08",
          "09",
          "10",
          "11",
          "12",
        ],
      },
    };
  },
  methods: {
    async submit() {
      try {
        this.load = true;
        let res = await httpService.post(
          endpoint["income.statement"] + (this.income_statement.id ? `/${this.income_statement.id}` : ''),
          this.income_statement,
          {
            headers: httpService.authHeader(),
          }
        );
        this.load = false;
        if (res.status == 200) {
          Swal.fire({
            title: "Berhasil",
            text: "data berhasil disimpan",
            icon: "success",
          });
          this.$router.push({
            name: "income.statement",
            params: {
              reload: true,
            },
          });
        }
      } catch (error) {
        this.load = false;
        errorService.displayError(error);
      }
    },

    sharePeriodEntitledToString() {
      let a = [];
      this.share_period_entitled.forEach(element => {
        a.push(element.name);
      });
      return a.join(', ');
    },

    async getShareEntitled() {
      let res = await httpService.get(httpService.generateEndpointQuery(endpoint['share.period'] + '/entitled', {
        year: this.income_statement.year,
        month: this.income_statement.month,
      }));
      this.share_period_entitled = res.data.data;
      this.share_total = res.data.share_total;
    },

    assignParams(params) {
      this.income_statement.id = params.id;
      this.income_statement.year = params.year;
      this.income_statement.month = params.month;
      this.income_statement.total_revenue = params.total_revenue;
      this.income_statement.total_expanses = params.total_expanses;
      this.income_statement._method = params.id ? "PUT" : "POST";
    },
  },
};
</script>

<style>
</style>